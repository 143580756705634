/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
/* eslint-disable operator-assignment */
/* eslint-disable prefer-destructuring */
// @ts-nocheck
import React, {
  useMemo,
} from 'react';
import styles from './questionsDistribution.module.scss';
import DistributedInput from './DistributedInput';
import RangerComponent from './RangerComponent';

interface IQestionsDistribution {
  questionsType: string,
  idInput: string,
  totalQuestions: number,
  inputs?: {
    [key: string]: {
      value: number;
      // label: string;
      // range: string;
    }
  },
  labels?: {
    [key: string]: {
      label: string;
      range: string;
      name: string;
    }
  },
  setInputs?: React.Dispatch<React.SetStateAction>,
}

function QuestionsDistribution(
  {
    questionsType,
    idInput,
    totalQuestions,
    inputs,
    labels,
    setInputs,
  } :
  IQestionsDistribution,
) {
  // function remainderFunction(total: number) {
  //   return total % Object.keys(inputs).length;
  // }

  // // function to divide the questions equally and create range values
  // function distribution(totalDistributable: number, remainder: number) {
  //   const obj: any = { ...inputs };
  //   const totalLessRemainder = totalDistributable - remainder;
  //   const equalNumber = totalLessRemainder / Object.keys(inputs).length;
  //   const newEntries = Object.entries(obj);
  //   // let cumul = 0;
  //   let isDistributed = false;
  //   const total = Object.values(inputs).reduce((acc, curr) => acc + curr.value, 0);
  //   if (total === totalDistributable) isDistributed = true;

  //   newEntries.forEach(([key], i: number) => {
  //     // if the input are not distributed to be equal to totalDistributable
  //     // compute the value
  //     if (!isDistributed) {
  //       if (i === newEntries.length - 1) {
  //         obj[key].value = equalNumber + remainder;
  //       } else {
  //         obj[key].value = equalNumber;
  //       }
  //     }
  //     // cumul += obj[key].value;
  //     // obj[key].rangeValue = cumul;
  //   });

  //   setInputs(obj);
  // }

  // function to change values of the object after moove ranges
  function handleChangeRange(arr: any) {
    const obj: any = { ...inputs };
    const newEntries = Object.entries(obj);
    const rangeValueOfLastIndex = totalQuestions;
    newEntries.forEach(([key], i: any) => {
      obj[key].value = arr[i];
      if (i === newEntries.length - 1) {
        obj[key].rangeValue = rangeValueOfLastIndex;
      } else {
        obj[key].rangeValue = arr[i];
      }
      if (i === 0) {
        obj[key].value = arr[0];
      } else {
        obj[key].value = obj[key].rangeValue - newEntries[i - 1][1].rangeValue;
      }
    });
    setInputs(obj);
  }

  // function to change the inputs and uptade the object
  const handleInputChange = (key: string, newValue: number) => {
    const obj: any = { ...inputs };

    const inputKeys = Object.keys(obj);
    const currentIndex = inputKeys.indexOf(key);
    const nextIndex = (currentIndex + 1) % inputKeys.length;
    const currentValue = obj[key].value;
    const difference = newValue - currentValue;

    if (currentIndex === inputKeys.length - 1) {
      obj[key].value = newValue;
      obj[inputKeys[nextIndex]].value = obj[inputKeys[nextIndex]].value - difference;
      obj[inputKeys[nextIndex]].rangeValue = obj[inputKeys[nextIndex]]
        .rangeValue - difference;
    } else if (currentIndex === inputKeys.length - 2) {
      obj[key].value = newValue;
      obj[key].rangeValue = obj[key].rangeValue + difference;
      obj[inputKeys[nextIndex]].value = obj[inputKeys[nextIndex]].value - difference;
    } else {
      obj[key].value = newValue;
      obj[key].rangeValue = obj[key].rangeValue + difference;
      obj[inputKeys[nextIndex]].value = obj[inputKeys[nextIndex]].value - difference;
      obj[inputKeys[nextIndex]].rangeValue = obj[inputKeys[nextIndex]].rangeValue - difference;
    }

    setInputs(obj);
  };

  // useEffect(() => {
  //   if (inputs) {
  //     distribution(totalQuestions, remainderFunction(totalQuestions));
  //   }
  // }, [totalQuestions]);

  // && Object.values(inputs).map((item: { value: number; label: string; range: string }) => (

  const inputsWithRangeValue = useMemo(() => {
    const obj: any = { ...inputs };
    const newEntries = Object.entries(obj);
    let cumul = 0;

    newEntries.forEach(([key]) => {
      cumul += obj[key].value;
      obj[key].rangeValue = cumul;
    });

    return obj;
  }, [inputs]);

  if (idInput === 'qru_questions') {
    console.log(inputsWithRangeValue);
  }

  return (
    <div className={styles.questionsDistribution}>
      <div className={styles.inputsContainer}>
        <div className={styles.totalQuestionsInput}>
          <label htmlFor={idInput}>
            {questionsType}
            <input type="text" name={idInput} id={idInput} value={totalQuestions} readOnly />
          </label>
        </div>
        <div className={styles.distributedInputsContainer}>
          { inputs
      && Object.entries(inputs).map(([key, item]: any) => (
        <DistributedInput
          key={labels[key]?.label}
          label={labels[key]?.label}
          name={labels[key]?.name}
          value={item.value}
          onInputChange={(newValue) => handleInputChange(key, newValue)}
          totalQuestions={totalQuestions}
        />
      ))}
        </div>
      </div>
      {
        inputsWithRangeValue
      && (
        <RangerComponent
          total={totalQuestions}
          entries={Object.entries(inputsWithRangeValue).map(([key, value]) => ({
            ...value,
            range: labels[key].range,
            key,
          }))}
          handleChange={(array:any) => handleChangeRange(array)}
        />
      )
      }
    </div>
  );
}

export default QuestionsDistribution;
