import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

// import OrganisationForm from '../../../../components/form/OrganisationForm';
// import { ErrorField } from '../../../../lib/HooksFormFields';
import { toast } from 'react-toastify';
import styles from './edit-session.module.scss';
import { useCreateSession, useGetSession, useUpdateSession } from '../../../../hooks/session';
import SessionForm from '../../../../components/form/SessionForm';

const EditSession = () => {
  const { sessionId } = useParams();
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const {
    data: session, error: errorGet,
  } = useGetSession(sessionId);
  // Create
  const {
    mutateAsync: createSession,
    isLoading: isPostLoading,
    error: errorPost,
  } = useCreateSession();
  // Update
  const {
    mutateAsync: updateSession,
    error: errorPut,
  } = useUpdateSession();

  const formRef = useRef<any>();
  const errorApi: any = errorGet || errorPost || errorPut;
  console.log(errorApi);

  const handleSubmit = async () => {
    const data = await formRef.current.submit();
    if (!data) return;
    if (session) {
      const { examSession: updatedSession }: any = await updateSession(data);
      if (updatedSession) {
        toast.success(t('form.success', { ns: 'sessions' }));
      }
    } else {
      const { examSession: createdSession }: any = await createSession(data);
      if (createdSession) navigate('/session');
    }
  };
  console.log(session);
  return (
    <div className={styles.editSession}>
      <button disabled={isPostLoading} onClick={handleSubmit}
        className={styles.submit}
      >
        {isPostLoading && <div className={styles.dotLoader} />}
        <span>{t('save', { ns: 'global' })}</span>
      </button>
      <div className={styles.content}>
        <SessionForm ref={formRef} session={session}/>
      </div>
    </div>
  );
};

export default EditSession;
