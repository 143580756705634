import React from 'react';
import { useTranslation } from 'react-i18next';
import { Control, UseFormWatch } from 'react-hook-form';
import {
  Checkbox, InputNumber, ListTags, StyledSelect,
} from '../../../../lib/HooksFormFields';
import styles from './additionnal-quiz-settings.module.scss';
import { useGetListQuizQuery } from '../../../../services/api/quiz';
import useOptionsLang from '../../../../hooks/useOptionsLang';

const AdditionnalQuizSettings = ({
  control,
  watchForm,
}: {
  control: Control<any>,
  watchForm: UseFormWatch<any>,
}) => {
  const { t } = useTranslation('createQuiz');
  const { data: list = {} } = useGetListQuizQuery();
  const statusOptions = useOptionsLang(list?.status || []);
  return (
    <>
        <div className={styles.block}>
          <h4>{t('settings.title')}</h4>
          <div className={`${styles.containerField} ${styles.select}`}>
            <StyledSelect
              name="status"
              control={control}
              label={t('settings.fields.status')}
              options={statusOptions}
            />
          </div>
          <div className={`${styles.containerField} ${styles.lang}`}>
            <ListTags
              name='lang'
              label={t('settings.fields.lang')}
              control={control}
              tags={[
                { label: t('settings.fields.langFr'), value: 'fr' },
                { label: t('settings.fields.langEn'), value: 'en' },
              ]}
            />
          </div>
          <p>{t('settings.animatorOptions')}</p>
          <div className={styles.containerField}>
            <Checkbox
              name='allowPrevious'
              label={t('settings.fields.back')}
              control={control}
            />
          </div>
          <div className={styles.containerField}>
            <Checkbox
              name='showCorrections'
              label={t('settings.fields.displayCorrection')}
              control={control}
            />
          </div>
          <div className={styles.containerField}>
            <Checkbox
              name='monitoredInterface'
              label={t('settings.fields.monitoredInterface')}
              control={control}
            />
          </div>
          <div className={styles.containerField}>
            <Checkbox
              name='limitedTime'
              label={t('settings.fields.limitedTime')}
              control={control}
            />
          </div>
          {watchForm('limitedTime')
            && <div className={`${styles.containerField} ${styles.time}`}>
              <InputNumber
                name='duration'
                control={control}
                unit={t('settings.fields.time')}
              />
            </div>
          }
        </div>
      </>
  );
};

export default AdditionnalQuizSettings;
