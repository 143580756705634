/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQueryClient,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import {
  getPlantsLists,
  getPlantsListById,
  createPlantsList,
  updatePlantsList,
  searchPlantsLists,
} from '../../api/plants';

import {
  IPlantsList,
  ArgsGetPlantsLists,
} from '../../types/plantsList';

const useGetPlantsLists = (params: ArgsGetPlantsLists) => useQuery({
  queryKey: ['PlantsLists', params],
  queryFn: async (): Promise<any | null> => getPlantsLists(params),
  keepPreviousData: true,
});

const useGetSearchPlants = (params: ArgsGetPlantsLists) => useQuery({
  queryKey: ['PlantsSearch', params],
  queryFn: async (): Promise<any | null> => searchPlantsLists(params),
  keepPreviousData: true,
  enabled: !!params.search,
});

const useGetPlantsListById = (id?: string) => useQuery({
  queryKey: ['PlantsList', id],
  queryFn: async (): Promise<IPlantsList | null | undefined> => getPlantsListById(id),
  keepPreviousData: true,
  enabled: !!id,
});

const useCreatePlantsList = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IPlantsList>) => createPlantsList(data),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['PlantsLists'] });
    },
  });
};

const useUpdatePlantsList = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IPlantsList>) => updatePlantsList(data),
    onSuccess: async ({ plantsList }) => {
      queryClient.invalidateQueries({ queryKey: ['PlantsLists'] });
      queryClient.setQueryData(['PlantsList', plantsList._id], plantsList);
    },
  });
};

export {
  useGetSearchPlants,
  useGetPlantsLists,
  useGetPlantsListById,
  useCreatePlantsList,
  useUpdatePlantsList,
};
