import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { MdOutlineLogout } from 'react-icons/md';
import { useWindowSize } from 'usehooks-ts';
import { useAppDispatch } from '../../stores';
import { apiUtil } from '../../services/api';
import logo from '../../assets/images/logo.svg';
import styles from './navbar.module.scss';

import IconMembers from './Icons/IconMembers';
import IconOrganisations from './Icons/IconOrganisations';
import IconQuiz from './Icons/IconQuiz';
import IconCenter from './Icons/IconCenter';
import IconPlants from './Icons/IconPlants';
import IconArticle from './Icons/IconArticles';

const NavBar = () => {
  const { t } = useTranslation('header');
  const dispatch: any = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authReducer = useSelector((d: any) => d.authReducer);
  const [isNavOpened, setIsNavOpened] = useState(true);
  const [open, setOpen] = useState('');
  const { user } = authReducer;
  const { width } = useWindowSize();

  const handleLogout = () => {
    dispatch(apiUtil.resetApiState());
    localStorage.removeItem('token');
    navigate('/login');
  };

  const getColorIcon = (url: string) => {
    let color = '#949494';
    if (location.pathname.includes(url)) color = '#282828';
    return color;
  };

  const getIsActiveMember = () => (
    location.pathname.includes('/users') || location.pathname.includes('/tags')
  );

  const getIsActiveQuestion = () => (
    location.pathname.includes('/quiz') || location.pathname.includes('/formation') || location.pathname.includes('/session')
  );

  const closeMenu = () => {
    if (!isNavOpened) return;
    setIsNavOpened(false);
    setOpen('');
  };

  useEffect(() => {
    if (isNavOpened && width < 1200) {
      setIsNavOpened(false);
    }
  }, [width]);

  return (
    <div className={isNavOpened ? `${styles.navbar} ${styles.open}` : styles.navbar}>
      <div className={styles.content}>
        {!isNavOpened && <div
          className={styles.overlay}
          onClick={() => setIsNavOpened(true)}
        />}
        <div className={styles.logo}>
          <div>
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className={styles.navigation}>
          <ul>
            <li >
              <span
                className={getIsActiveMember() ? `${styles.item} ${styles.active}` : styles.item}
                onClick={() => setOpen((state) => (state === 'members' ? '' : 'members'))}
              >
                <span className={styles.icon}><IconMembers fill={getIsActiveMember() ? '#282828' : '#949494'} /></span>
                <span className={styles.itemText}> {t('members')}</span>
              </span>
              {open === 'members' && (
                <ul className={styles.members}>
                  <li>
                    <NavLink
                      className={({ isActive }) => `${styles.item}${isActive ? ` ${styles.active}` : ''}`}
                      to="/users/tepik-team"
                      onClick={() => closeMenu()}
                    >
                      <span className={styles.itemText}>{t('teams-tepik')}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) => `${styles.item} ${isActive || location.pathname.includes('/tags/edit') ? ` ${styles.active}` : ''}`}
                      to="/tags/list"
                      onClick={() => closeMenu()}
                    >
                      <span className={styles.itemText}>{t('profils')}</span>
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li onClick={() => setOpen('')}>
              <NavLink
                className={() => `${styles.item}${location.pathname.includes('/organisations') ? ` ${styles.active}` : ''}`}
                to="/organisations/list"
                onClick={() => closeMenu()}
              >
                <span className={styles.icon}>
                  <IconOrganisations fill={getColorIcon('/organisations')} />
                </span>
                <span className={styles.itemText}> {t('organisations')}</span>
              </NavLink>
            </li>
            <li >
              <span
                className={getIsActiveQuestion() ? `${styles.item} ${styles.active}` : styles.item}
                onClick={() => setOpen((state) => (state === 'question' ? '' : 'question'))}
              >
                <span className={styles.icon}><IconQuiz fill={getIsActiveQuestion() ? '#282828' : '#949494'} /></span>
                <span className={styles.itemText}> {t('question')}</span>
              </span>
              {open === 'question' && (
                <ul className={styles.question}>
                  <li>
                    <NavLink
                      className={({ isActive }) => `${styles.item}${isActive ? ` ${styles.active}` : ''}`}
                      to="/quiz"
                      onClick={() => closeMenu()}
                    >
                      <span className={styles.itemText}> {t('quiz')}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) => `${styles.item}${isActive ? ` ${styles.active}` : ''}`}
                      to="/formation"
                      onClick={() => closeMenu()}
                    >
                      <span className={styles.itemText}>{t('formation')}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={
                        ({ isActive }) => `${styles.item}${isActive ? ` ${styles.active}` : ''}`
                      }
                      to="/session"
                      onClick={() => closeMenu()}
                    >
                      <span className={styles.itemText}>{t('session')}</span>
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li onClick={() => setOpen('')}>
              <NavLink
                className={({ isActive }) => `${styles.item}${isActive ? ` ${styles.active}` : ''}`}
                to="/plants"
                onClick={() => closeMenu()}
              >
                <span className={styles.icon}><IconPlants fill={getColorIcon('/plants')} /></span>
                <span className={styles.itemText}> {t('plants')}</span>
              </NavLink>
            </li>
            <li onClick={() => setOpen('')}>
              <NavLink
                className={({ isActive }) => `${styles.item}${isActive ? ` ${styles.active}` : ''}`}
                to="/centers"
                onClick={() => closeMenu()}
              >
                <span className={styles.icon}><IconCenter fill={getColorIcon('/centers')} /></span>
                <span className={styles.itemText}> {t('centers')}</span>
              </NavLink>
            </li>
            <li onClick={() => setOpen('')}>
              <NavLink
                className={({ isActive }) => `${styles.item}${isActive ? ` ${styles.active}` : ''}`}
                to="/actus"
                onClick={() => closeMenu()}
              >
                <span className={styles.icon}><IconArticle fill={getColorIcon('/actus')} /></span>
                <span className={styles.itemText}> {t('actus')}</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.bottom}>
        {user && (
          <div className={styles.user}>
            <NavLink to='/account'>
              <div className={styles.profilePicture}>
                <p>{user.profile.firstName[0]}.{user.profile.lastName[0]}</p>
              </div>
              <p className={styles.name}>{user.profile.firstName} {user.profile.lastName}</p>
            </NavLink>
          </div>
        )}
        <button className='invisible' onClick={handleLogout}>
          <span className='icon'><MdOutlineLogout /></span>
          <span>{t('logout')}</span>
        </button>
      </div>
    </div>
  );
};

export default NavBar;
