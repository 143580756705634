/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { ArgsGetSessions, ISession } from '../../types/sessions';

const getSessions = async (params: ArgsGetSessions) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/exam-sessions`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data?.examSessions;
};

const getSession = async (id?: string | null) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/exam-sessions/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.examSession;
};

const getSessionsList = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/exam-sessions/list`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.list;
};

const createSession = async (data: Partial<ISession>): Promise<{ examSession: ISession }> => {
  const token = localStorage.getItem('token');

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/exam-sessions`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

const updateSession = async (data: Partial<ISession>): Promise<{ examSession: ISession }> => {
  const token = localStorage.getItem('token');
  const { _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/exam-sessions/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const deleteSession = async (_id: string): Promise<any> => {
  const token = localStorage.getItem('token');
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/exam-sessions/${_id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export {
  getSessions,
  getSession,
  getSessionsList,
  createSession,
  updateSession,
  deleteSession,
};
