import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { IOption } from '../../types/options';
import selectStyles from './styles';
import styles from './filter-searchparam.module.scss';

type FilterSearchParamProps = {
  label: string,
  name: string,
  options: IOption[],
  feminine?: boolean,
  handleChangeParams?: (v:any) => void,
};

const FilterSearchParam = (
  {
    label,
    name,
    options,
    feminine = false,
    handleChangeParams,
  } : FilterSearchParamProps,
) => {
  const { t } = useTranslation('global');
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSetUrlSearchParam = (opt: IOption | null) => {
    const params = new URLSearchParams(searchParams);
    if (opt && opt.value !== 'all') params.set(name, opt.value);
    else if (!opt || opt?.value === 'all') params.delete(name);
    setSearchParams(params);
    if (handleChangeParams) {
      const paramsObject = Object.fromEntries(params);
      handleChangeParams(paramsObject);
    }
  };

  const searchValue = searchParams.get(name);

  const optionAll = { label: feminine ? t('all(f)') : t('all(m)'), value: 'all' };
  const allOptions = [
    optionAll,
    ...options,
  ];

  const value = useMemo(
    () => (searchValue
      ? (allOptions.find((opt) => opt.value === searchValue) || optionAll)
      : optionAll),
    [options, searchParams],
  );

  return (
    <div className={styles.container}>
      <label>{label}</label>
      <Select
        value={value}
        options={allOptions}
        styles={selectStyles}
        onChange={(opt) => {
          handleSetUrlSearchParam(opt);
        }}
        defaultValue={null}
        isSearchable={false}
      />
    </div>
  );
};

export default FilterSearchParam;
