import React, {
  forwardRef, useEffect, useImperativeHandle, useState, ChangeEvent,
} from 'react';
import { useForm } from 'react-hook-form';
// import { useTranslation } from 'react-i18next';

import { useTranslation } from 'react-i18next';

import { IoSearch } from 'react-icons/io5';
import { useDebounce } from 'usehooks-ts';
import styles from './plants-list-form.module.scss';
import { IPlantsList, PlantItem } from '../../../types/plantsList';
import useTagsToOptionsLang from '../../../hooks/useTagsToOptionsLang';
import { useGetFormationsList } from '../../../hooks/formations';
import SelectMultiple from '../../SelectMultiple';
import { ErrorField, InputText } from '../../../lib/HooksFormFields';
import { useGetSearchPlants } from '../../../hooks/plantsList';

const PlantsListForm = forwardRef(({
  plantsList,
  onSubmit,
  submitIsLoading,
} : {
  plantsList?: IPlantsList | null,
  onSubmit: () => void
  submitIsLoading?: boolean | null,
}, ref) => {
  const { t } = useTranslation('plantsLists');
  const { data: list } = useGetFormationsList();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce<string>(search, 500);

  const { data: searchPlants, isLoading: searchIsLoading } = useGetSearchPlants({
    search: debouncedSearch,
  });

  const {
    control,
    trigger,
    getValues,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({});
  const errorsForm: any = errors;

  const {
    tags = [],
  } = list || {};

  const tagsTranslated = useTagsToOptionsLang(tags);
  const plants = watch('plants') || [];

  const searchPlantsFiltered = searchPlants?.filter(
    (s: PlantItem) => !plants.find((p: PlantItem) => p.value === s.value),
  ).sort((a:PlantItem, b: PlantItem) => (a.label > b.label ? 1 : -1));

  useImperativeHandle(ref, () => ({
    submit: async () => {
      const isValid = await trigger();
      let data = null;
      if (isValid) {
        data = getValues();
      }
      return data;
    },
  }), []);
  console.log(errorsForm);

  function handleAddPlant(plant: PlantItem) {
    setValue('plants', [...plants, plant]);
  }

  function handleDeletePlant(plant: PlantItem) {
    setValue('plants', plants.filter((p:PlantItem) => p.value !== plant.value));
  }

  useEffect(() => {
    if (plantsList) {
      reset({
        ...plantsList,
        tags: plantsList.tags.map((tag: any) => tag._id),
      });
    }
  }, [plantsList]);

  return (
    <div className={styles.form}>
      <div className={styles.col}>
        <div className={styles.name}>
          <InputText
            control={control}
            name="name"
            heading={1}
            placeholder={t('form.name')}
            rules={{
              required: t('requiredField', { ns: 'global' }),
            }}
          />
          {errors.name?.message && <ErrorField message={errors.name.message}/>}
        </div>
        <section>
          <h2>{t('form.list')}</h2>
          <div
            className={styles.search}
          >
            <input
              value={search}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)
              }
            />
            <IoSearch />
          </div>
          {search.length > 2 && !searchIsLoading && searchPlantsFiltered?.length === 0
          && <>
              <p className={styles.message}>{t('form.notFound')} <span>{t('form.contact')}</span></p>
            </>
          }
          {search.length > 2 && !searchIsLoading && searchPlantsFiltered?.length > 0
          && <>
            <p className={styles.message}>{searchPlantsFiltered.length} {t('form.result')}</p>
            <div className={styles.list}>
              {search && searchPlantsFiltered.map((p: PlantItem) => (
                <div key={p.value} className={styles.item}>
                  <p>{p.label}</p>
                  <button
                    className={styles.add}
                    type="button"
                    onClick={() => handleAddPlant(p)}
                  >
                    {t('add', { ns: 'global' })}
                  </button>
                </div>
              ))}
            </div>
          </>
          }
          <div className={styles.list}>
            {plants?.map((p: PlantItem) => (
              <div key={p._id || p.value} className={styles.item}>
                <p>{p.label}</p>
                <button
                  className={styles.delete}
                  type="button"
                  onClick={() => handleDeletePlant(p)}
                >
                  {t('delete', { ns: 'global' })}
                </button>
              </div>
            ))}
          </div>
        </section>
      </div>
      <div className={styles.col}>
        <button
          type="button"
          className={styles.submit}
          onClick={onSubmit}>
          {submitIsLoading && <div className={styles.dotLoader} />}
          <span>{t('save', { ns: 'global' })}</span>
        </button>
        {plantsList?.creator?.profile.firstName
          && <p className={styles.creator}>
            {t('columns.creator')}
            {' : '}
            {plantsList?.creator?.profile?.firstName}
            {' '}
            {plantsList?.creator?.profile?.lastName}
        </p>}
        <div className={styles.tags}>
          <div className={styles.containerField}>
            <SelectMultiple
              label={t('title', { ns: 'tags' })}
              name="tags"
              control={control}
              options={tagsTranslated}
              isIcon
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default PlantsListForm;
