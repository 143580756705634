import React, {
  useEffect,
} from 'react';
import styles from './advanced-settings.module.scss';

const RecognitionAdvancedSettings = () => {
  useEffect(() => {}, []);

  return (
    <div className={styles.container}>
      <h1>RecognitionAdvancedSettings</h1>
    </div>
  );
};

export default RecognitionAdvancedSettings;
