import React from 'react';
import styles from './distributedInput.module.scss';

interface IDistributedInputProps {
  label: string;
  name: string;
  value: number;
  onInputChange: (newValue: number) => void;
  totalQuestions: number,
}

function DistributedInput({
  label, name, value, onInputChange, totalQuestions,
}: IDistributedInputProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = Number(e.target.value);
    if (newValue < 0) {
      newValue = 0;
    }
    if (newValue > totalQuestions) {
      newValue = totalQuestions;
    }
    onInputChange(newValue);
  };

  return (
    <div className={styles.distributedInput}>
      <label htmlFor={label}>
        <p>{name}</p>
        <input
          type="number"
          name={label}
          id={label}
          value={value}
          onChange={handleChange}
          disabled
        />
      </label>
    </div>
  );
}

export default DistributedInput;
