import React, { useImperativeHandle, forwardRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AsyncStyledSelect } from '../../../../lib/HooksFormFields';
import styles from './select-plants.module.scss';

type PlantProps = {
  value: string
  label: string | null
};

interface SelectPlantsRefProps {
  getPlants: () => void,
}

// eslint-disable-next-line max-len
const SelectPlants = forwardRef<SelectPlantsRefProps, { defaultPlants: PlantProps[] }>(({ defaultPlants }, ref) => {
  const { control } = useForm();
  const [plants, setPlants] = useState<PlantProps[]>(defaultPlants);

  useImperativeHandle(ref, () => ({
    getPlants: () => plants,
  }));

  const deletePlants = (plantId: string) => {
    const updatedPlants = plants.filter((plant) => plant.value !== plantId);
    setPlants(updatedPlants);
  };

  return (
    <div className={styles.plants}>
      <AsyncStyledSelect
        name='plants'
        endpoint='/plants/list'
        control={control}
        handleChange={(plant: PlantProps) => {
          if (plant) setPlants((state) => [plant, ...state]);
        }}
      />
      <div>
        <div>
          <ul>
            {plants.map((plant: PlantProps) => (
              <li key={`plant-${plant.value}`}>
                <div className={styles.plant}>
                  <p>{plant.label}</p>
                  <p onClick={() => deletePlants(plant.value)}>x</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
});

export default SelectPlants;
