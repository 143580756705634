import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { FaArrowLeft } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import {
  useCreateQuizMutation,
  ArgsPostQuiz,
} from '../../../services/api/quiz';
import QuizForm from '../../../components/form/QuizForm';
import { get } from '../../../utils';
import { ErrorField } from '../../../lib/HooksFormFields';
import styles from './create.module.scss';
import AdditionnalQuizSettings from '../../../components/form/QuizForm/AdditionnalQuizSettings';

const requiredFields = [
  'name',
  'description',
  'settings.recognitionCount',
  'settings.recognitionCount',
  'settings.recognition.QRU',
  'settings.recognition.QRM',
  'settings.recognition.QRA',
  'settings.knowledge.QRU',
  'settings.knowledge.QRM',
  'settings.knowledge.QRA',
];

const CreateQuiz = () => {
  const { t } = useTranslation(['global', 'createQuiz']);
  const navigate = useNavigate();
  const [createQuiz, { isLoading, error: errorCreate }] = useCreateQuizMutation();
  const errorRequest: any = errorCreate;

  const {
    control,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // defaultValues: {
    //   status: 'draft',
    //   type: 'private',
    //   typePlantName: 'scientific_name',
    // },
    defaultValues: {
      name: '',
      description: 'Lorem ipsum',
      status: 'draft',
      typePlantName: 'random',
      type: 'private',
      lang: 'fr',
      showCorrections: true,
      allowPrevious: true,
      settings: {
        recognitionSelection: 'all',
        recognitionFilters: {},
        knowledgeFilters: {},
        recognitionCount: 10,
        recognition: {
          QRU: { value: 4 },
          QRM: { value: 3 },
          QRA: { value: 2 },
          QRT: { value: 1 },
        },
        knowledgeSelection: 'all',
        knowledgeCount: 10,
        knowledge: {
          QRU: { value: 0 },
          QRM: { value: 0 },
          QRA: { value: 0 },
        },
      },
    },
  });

  const submit = async (data: FieldValues) => {
    const { settings } = data;
    const parsedRecognition: any = {};
    const parsedKnowledge: any = {};

    Object.entries(settings.recognition).forEach(([key, d]: any) => {
      parsedRecognition[key] = d.value;
    });

    Object.entries(settings.knowledge).forEach(([key, d]: any) => {
      parsedKnowledge[key] = d.value;
    });

    const { data: quiz } : any = await createQuiz({
      ...data,
      settings: {
        ...settings,
        recognition: parsedRecognition,
        knowledge: parsedKnowledge,
      },
    } as ArgsPostQuiz);
    if (quiz) {
      navigate(`/quiz/edit/${quiz._id}`);
    }
  };

  return (
    <div className={styles.create}>
      <div className={styles.header}>
        <NavLink to="/quiz">
          <FaArrowLeft />
          {t('back', { ns: 'global' })}
        </NavLink>
        <p>{t('title', { ns: 'createQuiz' })}</p>
      </div>
      <div className={styles.form}>
        <QuizForm
          control={control}
          watch={watch}
          getValues={getValues}
          setValue={setValue}
        />
      </div>
      <div className={styles.moreSettings}>
        <button type='button' disabled={isLoading} onClick={handleSubmit(submit)}>
          <span>{t('save', { ns: 'createQuiz' })}</span>
          {isLoading && (
            <span className='icon loader'>
              <div className={styles.dotLoader} />
            </span>
          )}
        </button>
        {!!Object.keys(errors).length
        && requiredFields.map((key) => (get(errors, key)?.message ? (
          <div key={key} className={styles.errorMessage}>
            <ErrorField
              key={`error-${key}`}
              message={get(errors, key)?.message || ''}
            />
          </div>
        ) : null))
          .filter((d) => d)[0]}
        {errorRequest?.message && (
          <ErrorField message={errorRequest.message} />
        )}
        <AdditionnalQuizSettings
          control={control}
          watchForm={watch}
        />
      </div>
    </div>
  );
};

export default CreateQuiz;
