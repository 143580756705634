import React, { useEffect } from 'react';
import {
  Routes, Route, useNavigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SignIn from './pages/signin';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import { useGetProfileMutation } from './services/api/auth';
import { setCredentials } from './stores/slices/authSlice';
import Layout from './components/Layout';
import Users from './pages/users';
import Quiz from './pages/quiz';
import Tags from './pages/tags';
import Organisations from './pages/organisations';
import Centers from './pages/centers';
import Account from './pages/account';
import Formation from './pages/formation';
import Session from './pages/session';

import PlantsLists from './pages/plantsLists';
import Articles from './pages/articles';

const Redirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getProfile] = useGetProfileMutation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) navigate('/login');

    getProfile().unwrap().then((res) => {
      dispatch(setCredentials(res));
    }).catch(() => {
      navigate('/login');
    });
  }, []);

  return (
    <div style={{
      position: 'fixed',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <p>Loading...</p>
    </div>
  );
};

const AppRoutes = () => {
  const { user } = useSelector((store: any) => store.authReducer);

  return (
    <Routes>
      <Route path='/login' element={<SignIn />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/reset-password/:token' element={<ResetPassword />} />
      {user && (
        <Route path='*' element={
          <Layout key='app'>
            <Routes>
              <Route path='/account' element={<Account />} />
              <Route path='/users/*' element={<Users />} />
              <Route path='/quiz/*' element={<Quiz />} />
              <Route path='/tags/*' element={<Tags />} />
              <Route path='/organisations/*' element={<Organisations />} />
              <Route path='/centers/*' element={<Centers />} />
              <Route path='/plants/*' element={<PlantsLists />} />
              <Route path='/session/*' element={<Session />} />
              <Route path='/formation/*' element={<Formation />} />
              <Route path='/actus/*' element={<Articles />} />
            </Routes>
          </Layout>
        }/>
      )}
      <Route path='*' element={<Redirect />} />
    </Routes>
  );
};

export default AppRoutes;
