import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdRefresh } from 'react-icons/io';
import {
  useRegenerateQuestionQuizMutation,
  useChangeQuestionPictureMutation,
} from '../../services/api/quiz';
import Select from './Select';
import SearchPlant from './SearchPlant';
import Loader from '../Loader';
import styles from './question.module.scss';
import { IQuestion } from '../../types/questions';

type handleRegenerateQuestionArgs = {
  plant?: number,
};

const Question = ({
  question,
  index,
} : {
  question: IQuestion,
  index: number,
}) => {
  const { t } = useTranslation(['createQuiz']);
  const token = localStorage.getItem('token');
  const [
    regenerateQuizQuestion,
    { isLoading: isRegenerateLoading, error: errorUpdate },
  ] = useRegenerateQuestionQuizMutation();

  const [
    changeQuestionPicture,
    { isLoading: isPictureLoading, error: errorChangePicture },
  ] = useChangeQuestionPictureMutation();

  const isUpdateLoading = isRegenerateLoading || isPictureLoading;

  const getImage = useCallback((picture: any) => `${process.env.REACT_APP_API_URL}/quiz/pictures/${picture?.directory?.replace('../', '')}/${picture.file_name}?token=${token?.replace('JWT ', '')}`, []);

  const handleRegenerateQuestion = (params: handleRegenerateQuestionArgs = {}) => {
    regenerateQuizQuestion({ _id: question._id, ...params });
  };

  const handleChangeQuestionPicture = (path: string) => {
    changeQuestionPicture({ _id: question._id, path });
  };

  useEffect(() => {
    console.log(errorUpdate || errorChangePicture);
  }, [errorUpdate, errorChangePicture]);

  const generalType = question.type.slice(0, 3);

  const defaultOptionsDistractors = [
    { label: '0', value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
  ];

  const optionsDistractors = useMemo(() => {
    if (generalType === 'QRU') return [{ label: '2', value: 2 }];
    return defaultOptionsDistractors;
  }, [generalType]);

  const defaultDistractor = question.options.filter((d) => !d.isCorrect).length;

  return (
    <div className={isUpdateLoading ? `${styles.question} ${styles.loading}` : styles.question}>
      {isUpdateLoading && (
        <div className={styles.loader}>
          <Loader small/>
        </div>
      )}
      <div className={styles.header}>
        <div>
          <h3>
            Question {index}
          </h3>
          <p className={styles.generalType}>{generalType}</p>
        </div>
        <div className={styles.options}>
          {(generalType === 'QRU' || generalType === 'QRM') && (
            <Select
              label={t('preview.distractors')}
              defaultValue={defaultDistractor}
              options={optionsDistractors}
            />
          )}
          <SearchPlant
            onSubmit={(plantId: number) => handleRegenerateQuestion({ plant: plantId })}
            isLoading={isUpdateLoading}
          />
          <button
            className={`${styles.refresh} secondary`}
            onClick={() => handleRegenerateQuestion()}
          >
            <IoMdRefresh size={20} />
          </button>
        </div>
      </div>
      {question.pictures.length > 0 && (
        <div className={styles.questionPictures}>
          {question.pictures.map((d: any, i: number) => (
            <div className={styles.picture} key={`picture-${d.id}`}>
              <img
                src={getImage(d)}
              />
              <button onClick={() => handleChangeQuestionPicture(`pictures.${i}`)}>
                <IoMdRefresh />
              </button>
            </div>
          ))}
        </div>
      )}
      <p className={styles.text} dangerouslySetInnerHTML={{ __html: question.text }} />
      <div className={styles.options}>
        {question.options.map((option: any, optionIndex: number) => (
          <div className={styles.option} key={`option-${option._id}`}>
            {option.text && <p dangerouslySetInnerHTML={{ __html: option.text }} />}
            {option.pictures.length > 0 && (
              <div className={styles.pictures}>
                {option.pictures.map((d: any, picturesIndex: number) => (
                  <div className={styles.picture} key={`picture-${d.id}`}>
                    <img
                      src={getImage(d)}
                    />
                    <button onClick={() => handleChangeQuestionPicture(`options.${optionIndex}.pictures.${picturesIndex}`)}>
                      <IoMdRefresh />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Question;
