import React, {
  useMemo, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';
import styles from './sessions-table.module.scss';

import { ISession } from '../../types/sessions';

const SessionsTable = (
  {
    sessions,
    handleSorting,
  } : {
    sessions: ISession[],
    handleSorting: any,
  },
) => {
  const { t } = useTranslation('sessions');

  const columns = [{
    header: t('columns.name'),
    accessorKey: 'name',
  },
  {
    header: t('columns.sessionId'),
    accessorKey: 'sessionId',
  },
  {
    header: t('columns.date'),
    cell: (row: any) => {
      const date = row.getValue() && format(new Date(row.getValue()), 'dd/MM/yyyy HH:mm');
      return (<span>{date}</span>);
    },
    accessorKey: 'date',
  },
  {
    header: t('columns.count'),
    cell: (row: any) => (<span>{row.getValue()}</span>),
    accessorKey: 'countLearners',
  },
  {
    header: t('columns.state'),
    cell: (row: any) => {
      const date = row.getValue() && new Date(row.getValue());
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const tomorrow = new Date(today.setDate(today.getDate()));
      const isActive = date.getTime() > tomorrow.getTime() || date.getDate() === today.getDate();
      console.log(today);
      return (<div className={styles.status}>
        <p className={`${styles.statusLabel} ${isActive ? '' : styles.close}`}>{isActive ? 'En cours' : 'Terminé'}</p>
      </div>);
    },
    accessorKey: 'date',
  }];

  const [sorting, setSorting] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    handleSorting(sorting[0]);
  }, [sorting]);

  const data = useMemo(() => sessions, [sessions]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  const onRowClick = (e: any, row: Row<ISession>) => {
    e.preventDefault();
    navigate(`/session/detail/${row.original._id}`);
  };

  return (
    <div className={styles.sessionsTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={
                    !['type', 'tags'].includes(header.id)
                      ? header.column.getToggleSortingHandler()
                      : () => {}
                  }
                >
                  {!['type', 'tags'].includes(header.id) && (
                    <>
                      {typeof header.column.getIsSorted() === 'string' ? (
                        {
                          asc: <IoChevronUpOutline />,
                          desc: <IoChevronDownOutline />,
                        }[header.column.getIsSorted() as string]
                      ) : (
                        <span className={styles.sortIcons}>
                          <IoChevronUpOutline />
                          <IoChevronDownOutline />
                        </span>
                      )}
                    </>
                  )}
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={(e) => onRowClick(e, row)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext(),
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SessionsTable;
