/* eslint-disable no-alert */
import React, {
  forwardRef, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import { LuTrash2, LuMove } from 'react-icons/lu';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FieldValues, useForm } from 'react-hook-form';
import { FiDownload } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import Select from 'react-select';

import { format } from 'date-fns';
import { IoAdd } from 'react-icons/io5';
import axios from 'axios';
import { toast } from 'react-toastify';
import selectStyles from '../../FilterSearchParam/styles';
import styles from './article-form.module.scss';

import useTagsToOptionsLang from '../../../hooks/useTagsToOptionsLang';
import SelectMultiple from '../../SelectMultiple';
import { ErrorField, InputText, Textarea } from '../../../lib/HooksFormFields';
import { IArticle, ArticleField } from '../../../types/article';
import InputFile from '../../InputFile';
import titleIcon from './images/title.svg';
import textIcon from './images/text.svg';
import pictureIcon from './images/picture.svg';
import videoIcon from './images/video.svg';
import { IStatusOption } from '../../../types/options';
import InputDate from '../../../lib/HooksFormFields/InputDate';
import TextEditor from '../../../lib/HooksFormFields/TextEditor';
import { useGetArticlesList } from '../../../hooks/articles';

const ItemType = 'FIELD';

const Field = ({
  field,
  index,
  control,
  moveField,
  handleDeleteField,
  handleAddFieldMedia,
}:{
  field: ArticleField,
  index: number,
  control: any
  handleDeleteField: () => void,
  handleAddFieldMedia: (i: number, m: string) => void,
  moveField: (a: number, b: number) => void,
}) => {
  const fieldRef = useRef(null);
  const { t } = useTranslation('articles');
  console.log(field);
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (item: { index: number }) => {
      if (item.index !== index) {
        moveField(item.index, index);
        // eslint-disable-next-line no-param-reassign
        item.index = index;
      }
    },
    collect: (monitor) => ({
      hoveredIndex: monitor.getItem()?.index,
    }),
  });

  drag(drop(fieldRef));

  return (
    <div ref={fieldRef} className={`${styles.field} ${isDragging ? styles.dragging : ''}`}>
      {!isDragging && <div className={styles.actions}>
        <div
          className={styles.icon}
          onClick={() => handleDeleteField()}
        >
          <LuTrash2 />
        </div>
        <div className={styles.icon}>
          <LuMove />
        </div>
      </div>
      }

        {field.type === 'H1'
        && <div className={styles.input}>
            <InputText
              control={control}
              name={`fields[${index}].value`}
              heading={1}
              placeholder={t('form.fields.title')}
              rules={{
              }}
            />
          </div>
        }
        {field.type === 'text'
        && <div className={styles.input}>
          <TextEditor
            control={control}
            name={`fields[${index}].value`}
            placeholder={t('form.fields.text')}
            rules={{
            }}
          />
          </div>
        }
        {(field.type === 'image' || field.type === 'video')
          && <InputFile
          fileId={field?.media?._id}
          submit={(cover: string) => handleAddFieldMedia(index, cover) }
          id={`fields[${index}]`}
        >
          {field?.media?.path
            ? <>
              <div className={`${styles.cover} ${styles.media}`}>
                {field.media.mimetype !== 'video/mp4'
                  ? <img src={`${process.env.REACT_APP_API_URL}/files/public/${field?.media.path}`}
                  className={styles.img}
                />
                  : <video controls>
                    <source
                      src={`${process.env.REACT_APP_API_URL}/files/public/${field?.media.path}`}
                      type={field.media.mimetype}
                    />
                </video>
                }
            </div>
          </> : <div className={`${styles.cover}`}>
            <FiDownload /><p> {field.type === 'video' ? t('form.video') : t('form.picture')}</p>
          </div>}
        </InputFile>
        }
    </div>
  );
};

const AddField = ({
  handleAddField,
}:{
  handleAddField: (val: ArticleField) => void
}) => {
  const [open, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation('articles');
  const fieldsOptions : any = [
    {
      value: {
        value: '',
        type: 'H1',
        media: null,
      },
      label: t('form.fields.title'),
      icon: titleIcon,
    },
    {
      value: {
        value: '',
        type: 'text',
        media: null,
      },
      label: t('form.fields.text'),
      icon: textIcon,
    },
    {
      value: {
        value: '',
        type: 'image',
        media: null,
      },
      label: t('form.fields.image'),
      icon: pictureIcon,
    },
    {
      value: {
        value: '',
        type: 'video',
        media: null,
      },
      label: t('form.fields.video'),
      icon: videoIcon,
    },
  ];

  return <div className={styles.add}>
    <button
      type='button'
      className={`${styles['add-btn']} ${open ? styles.open : ''}`}
      onClick={() => setIsOpen(true)}
    >
      <IoAdd />
    </button>
    <div onMouseLeave={() => {
      if (open) {
        setIsOpen(false);
      }
    }} className={`${styles['option-container']} ${open ? styles.open : ''}`}>
      <div className={styles.options}>
        {fieldsOptions.map((f: any) => <button
          key={f.label}
          className={styles.btn}
          type="button"
          onClick={() => handleAddField(f.value)}
        >
          <div className={styles.icon}>
            <img src={f.icon} alt={f.label} />
          </div>
          <p>{f.label}</p>
        </button>)}
      </div>
    </div>
  </div>;
};

const ArticleForm = forwardRef(({
  article,
  onSubmit,
  updateArticle,
  submitIsLoading,
  deleteArticle,
} : {
  article?: IArticle | null,
  updateArticle: (val: any) => void,
  deleteArticle: () => void
  onSubmit: () => void
  submitIsLoading?: boolean | null,
}, ref) => {
  const { t } = useTranslation('articles');
  const { data: list } = useGetArticlesList();

  const date = article?.updatedAt ? format(new Date(article.updatedAt), 'dd-MM-yyyy')?.toString() : null;
  const hour = article?.updatedAt ? format(new Date(article.updatedAt), 'HH:mm')?.toString() : null;
  const {
    control,
    trigger,
    getValues,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm<FieldValues>({
    defaultValues: {
      isPublished: false,
    },
  });

  const {
    tags = [],
    organisations = [],
    regions = [],
  } = list || {};
  const fields = watch('fields') || [];
  const form = watch();

  const tagsTranslated = useTagsToOptionsLang(tags);
  const statusOptions : IStatusOption[] = [
    {
      label: t('form.status.draft'),
      value: false,
    },
    {
      label: t('form.status.published'),
      value: true,
    },
  ];
  const isPublished : IStatusOption = statusOptions.find(
    (s: IStatusOption) => s.value === watch('isPublished'),
  ) || statusOptions[0];

  const moveField = (dragIndex: number, hoverIndex: number) => {
    const updatedFields : ArticleField[] = [...fields];
    const [movedItem] = updatedFields.splice(dragIndex, 1);
    updatedFields.splice(hoverIndex, 0, movedItem);
    const reorderedQuizList : ArticleField[] = updatedFields.map(
      (q: ArticleField, i: number) => ({ ...q, index: i }),
    );
    setValue('fields', reorderedQuizList);
  };

  function handleAddField(field: any) {
    const updatedFields = [...fields, { ...field }].map((f:ArticleField) => ({
      ...f,
      media: f?.media?._id,
    }));
    updateArticle({ _id: article?._id, ...form, fields: updatedFields });
  }

  function handleAddFieldMedia(index: number, mediaId: string) {
    const updatedFields = [...fields].map((f:ArticleField) => ({
      ...f,
      media: f?.media?._id,
    }));
    updatedFields[index].media = mediaId;
    updateArticle({ _id: article?._id, ...form, fields: [...updatedFields] });
  }

  async function handleDeleteField(index: number) {
    const updatedFields = [...fields].map((f:ArticleField) => ({
      ...f,
      media: f?.media?._id,
    }));
    if (window.confirm(t('form.fields.delete'))) {
      const token = localStorage.getItem('token');
      const mediaId = updatedFields[index]?.media;
      if (mediaId) {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/files/${mediaId}`,
          {
            headers: {
              Authorization: token,
            },
          },
        );
      }
      await updateArticle({
        _id: article?._id,
        ...form,
        fields: [...updatedFields.filter((d, i) => i !== index)],
      });
    }
  }

  async function handleChangePublicationStatus(status: boolean) {
    const { article: updatedArticle }: any = await updateArticle({
      _id: article?._id,
      ...form,
      isPublished: status,
    });
    if (updatedArticle) {
      toast.success(t('form.success', { ns: 'articles' }));
    }
  }

  useImperativeHandle(ref, () => ({
    submit: async () => {
      const isValid = await trigger();
      let data = null;
      if (isValid) {
        data = getValues();
      }
      return data;
    },
  }), []);

  useEffect(() => {
    if (article) {
      const publicationDate = article.publicationDate ? format(new Date(article.publicationDate), 'yyyy-MM-dd') : null;
      reset({
        ...article,
        publicationDate,
        organisations: article.organisations.map((tag: any) => tag._id),
        tags: article.tags.map((tag: any) => tag._id),
      });
    }
  }, [article]);

  return (
    <div className={styles.form}>
      <div className={styles.col}>
        <div className={styles.name}>
          <InputText
            control={control}
            name="title"
            heading={1}
            placeholder={t('form.title')}
            rules={{
              required: t('requiredField', { ns: 'global' }),
            }}
          />
          {errors.name?.message && <ErrorField message={errors.name.message}/>}
        </div>
        <div className={styles.description}>
          <Textarea
            control={control}
            name="description"
            rows={1}
            className={'discret'}
            placeholder={t('form.description')}
            rules={{
              required: t('requiredField', { ns: 'global' }),
            }}
          />
          {errors.description?.message && <ErrorField message={errors.description.message}/>}
        </div>
        {article?._id
          && <InputFile
          fileId={article?.cover?._id}
          submit={(cover: string) => updateArticle({ _id: article?._id, ...form, cover }) }
          id={'profile.picture'}
        >
          {article?.cover?.path
            ? <>
              <div className={`${styles.cover} ${article?.cover?.path && styles.media}`}>
                <img src={`${process.env.REACT_APP_API_URL}/files/public/${article?.cover.path}`}
                  className={styles.img}
                />
            </div>
          </> : <div className={`${styles.cover}`}>
            <FiDownload /><p> {t('form.cover')}</p>
          </div>}
        </InputFile>
        }
         {article?._id
         && <>
          <DndProvider backend={HTML5Backend}>
          <div className={styles.fields}>
            {fields.map((f: ArticleField, i: number) => <Field
              key={f?._id}
              field={f}
              index={i}
              control={control}
              moveField={moveField}
              handleAddFieldMedia={handleAddFieldMedia}
              handleDeleteField={() => handleDeleteField(i)}
            />)}
          </div>
          </DndProvider>
          <AddField
            handleAddField={handleAddField}
          />
          </>
         }
      </div>
      <div className={styles.col}>
        <button
          type="button"
          className={`${styles.submit} ${isDirty ? '' : styles.saved}`}
          onClick={onSubmit}>
          {submitIsLoading && <div className={styles.dotLoader} />}
          <span>{t('form.save')}</span>
        </button>
        {article?.creator && date
            && <p className={styles.creator}>
              {t('form.update', {
                date,
                hour,
              })}
              <br/>
               {t('form.user', {
                 user: `${article?.creator?.profile?.firstName[0]}. ${article?.creator?.profile?.lastName}`,
               })}
        </p>}
        <div className={styles.block}>
          <h4>{t('form.infos')}</h4>
          <div className={styles.status}>
            <label>{t('form.status.label')} :</label>
            <Select
              value={isPublished}
              options={statusOptions}
              styles={selectStyles}
              onChange={(opt : any) => handleChangePublicationStatus(opt.value)}
              defaultValue={null}
              isSearchable={false}
            />
          </div>
          <div className={styles.containerField}>
            <label>{t('form.tags')}</label>
            <SelectMultiple
              name="tags"
              control={control}
              options={tagsTranslated}
              isIcon
            />
          </div>
        </div>
        <div className={styles.block}>
          <h4>{t('form.publication')}</h4>
          <div className={styles.date}>
            <label>{t('form.publicationDate')} :</label>
            <div className={styles.input}>
              <InputDate
                name='publicationDate'
                control={control}
              />
            </div>
          </div>
          <div className={styles.containerField}>
            <label>{t('form.organisations')}</label>
            <SelectMultiple
              name="organisations"
              control={control}
              options={organisations}
              isIcon
            />
          </div>
          <div className={styles.containerField}>
            <label>{t('form.regions')}</label>
            <SelectMultiple
              name="regions"
              control={control}
              options={regions}
              isIcon
            />
          </div>
        </div>
        <div
          onClick={() => deleteArticle()}
          className={styles.delete}
        >
          {t('delete', { ns: 'global' })}
        </div>
      </div>
    </div>
  );
});

export default ArticleForm;
