import React, {
  forwardRef, useImperativeHandle,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  InputText,
  InputEmail,
  InputNumber,
  InputFormatPattern,
  StyledSelect,
  ErrorField,
  Textarea,
} from '../../../lib/HooksFormFields';

import { useGetUsersList } from '../../../hooks/users';
import styles from './center-form.module.scss';
import { ICenter } from '../../../types/centers';

const CenterForm = forwardRef(({ center } : { center?: ICenter | null }, ref) => {
  const { t } = useTranslation(['global', 'createCenter']);
  const {
    control,
    trigger,
    getValues,
    formState: { errors },
  } = useForm(center ? {
    defaultValues: center,
  } : {});
  const errorsForm: any = errors;

  const {
    data: list,
  } = useGetUsersList();

  useImperativeHandle(ref, () => ({
    submit: async () => {
      const isValid = await trigger();
      let data = null;
      if (isValid) {
        data = getValues();
      }
      return data;
    },
  }), []);
  console.log(errorsForm);
  return (
    <div className={styles.form}>
      <section>
        <h2>{t('title', { ns: 'createCenter' })}</h2>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <InputText
              name="name"
              control={control}
              label={t('fields.name', { ns: 'createCenter' })}
              rules={{
                required: t('requiredField', { ns: 'global' }),
              }}
            />
            {errors?.name && <ErrorField message={errors.name.message} />}
          </div>
        </div>
        <div className={styles.row}>
        <div className={styles.containerField}>
          <InputText
            name="address.street"
            control={control}
            label={t('fields.address', { ns: 'createCenter' })}
          />
          {errorsForm?.address?.street && (
            <ErrorField message={errorsForm.address?.street?.message} />
          )}
        </div>
      </div>
      <div className={styles.row}>
        <div className={`${styles.containerField} ${styles.shortField}`}>
          <InputText
            name="address.zipCode"
            control={control}
            label={t('fields.zipCode', { ns: 'createCenter' })}
          />
          {errorsForm?.address?.zipCode && (
            <ErrorField message={errorsForm.address?.zipCode?.message} />
          )}
        </div>
        <div className={styles.containerField}>
          <InputText
            name="address.region"
            control={control}
            label={t('fields.region', { ns: 'createCenter' })}
          />
          {errorsForm?.address?.region && (
            <ErrorField message={errorsForm.address?.region?.message} />
          )}
        </div>
      </div>
      <div className={styles.companyFields}>
        <div className={styles.containerField}>
          <InputText
            name="siret"
            control={control}
            label={t('fields.siret', { ns: 'createOrganisation' })}
            rules={{
              pattern: {
                value: /^.{9,14}$/,
                message: 'Le SIRET n\'est pas valide',
              },
            }}
          />
          {errors.siret && <ErrorField message={errors.siret.message} />}
        </div>
        <div className={styles.containerField}>
          <InputText
            name="codeAPE"
            control={control}
            label={t('fields.codeAPE', { ns: 'createOrganisation' })}
          />
        </div>
        <div className={styles.containerField}>
          <InputText
            name="tva"
            control={control}
            label={t('fields.tva', { ns: 'createOrganisation' })}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <StyledSelect
            name="organisation"
            control={control}
            label={t('fields.organisation', { ns: 'createCenter' })}
            options={list?.organisations || []}
          />
        </div>
        <div className={`${styles.containerField} ${styles.shortField}`}>
          <InputNumber
            name="capacity"
            control={control}
            label={t('fields.capacity', { ns: 'createCenter' })}
            min='0'
            rules={{
              required: t('requiredField', { ns: 'global' }),
            }}
          />
           {errorsForm?.capacity
            && (
              <ErrorField message={errorsForm.capacity?.message} />
            )}
        </div>
      </div>
      <div className={styles.row}>
          <div className={styles.containerField}>
            <InputText
              name="schoolId"
              control={control}
              label={t('fields.schoolId', { ns: 'createCenter' })}
              rules={{
                required: t('requiredField', { ns: 'global' }),
              }}
            />
            {errors?.schoolId && <ErrorField message={errors.schoolId.message} />}
          </div>
        </div>
      <div className={styles.note}>
        <Textarea
          name="note"
          control={control}
          label={t('fields.note', { ns: 'createOrganisation' })}
        />
      </div>
      </section>
      <section>
      <h2>{t('contact', { ns: 'createCenter' })}</h2>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputText
            name="contact.lastName"
            control={control}
            label={t('fields.lastName', { ns: 'createCenter' })}
            rules={{
              required: t('requiredField', { ns: 'global' }),
            }}
          />
          {errorsForm?.contact?.lastName && (
            <ErrorField message={errorsForm.contact.lastName.message} />
          )}
        </div>
        <div className={styles.containerField}>
          <InputText
            name="contact.firstName"
            control={control}
            label={t('fields.firstName', { ns: 'createCenter' })}
            rules={{
              required: t('requiredField', { ns: 'global' }),
            }}
          />
          {errorsForm?.contact?.firstName && (
            <ErrorField message={errorsForm.contact.firstName.message} />
          )}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputFormatPattern
            name="contact.phone"
            control={control}
            label={t('fields.phone', { ns: 'createCenter' })}
            format="## ## ## ## ##"
            rules={{
              pattern: {
                value: /([0-9]{2,2}\s){4,5}([0-9]{2,2})/,
                message: t('required.formatPhone', { ns: 'createCenter' }),
              },
            }}
          />
          {errorsForm?.contact?.phone && (
            <ErrorField message={errorsForm?.contact.phone.message} />
          )}
        </div>
        <div className={styles.containerField}>
          <InputEmail
            name="contact.email"
            control={control}
            required
            label={t('fields.email', { ns: 'createCenter' })}
            className="login"
          />
          {errorsForm?.contact?.email && <ErrorField message={errorsForm.contact.email.message} />}
        </div>
      </div>
      </section>
    </div>
  );
});

export default CenterForm;
