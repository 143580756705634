/* eslint-disable @typescript-eslint/no-shadow */
import React, { useRef } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import PlantsListForm from '../../../components/form/PlantsListForm';
import {
  useCreatePlantsList,
  useGetPlantsListById,
  useUpdatePlantsList,
} from '../../../hooks/plantsList';

import { ErrorField } from '../../../lib/HooksFormFields';
// import CenterForm from '../../../components/form/CenterForm';
import styles from './edit.module.scss';

const EditPlantsList = () => {
  const { id: plantsListId } = useParams();

  const navigate = useNavigate();
  const {
    data: plantsList, isLoading: isGetLoading, error: errorGet,
  } = useGetPlantsListById(plantsListId);
  // Create
  const {
    mutateAsync: create,
    isLoading: isPostLoading,
    error: errorPost,
  } = useCreatePlantsList();
  // Update
  const {
    mutateAsync: update,
    isLoading: isPutLoading,
    error: errorPut,
  } = useUpdatePlantsList();

  const formPlantsListRef = useRef<any>();
  const errorApi: any = errorGet || errorPost || errorPut;

  const handleSubmit = async () => {
    const data = await formPlantsListRef.current.submit();
    if (!data) return;
    if (plantsList) {
      const { plantsList: updatedPlantsList }: any = await update(data);
      console.log(updatedPlantsList);
      if (updatedPlantsList) navigate('/plants');
    } else {
      const { plantsList: newPlantsList }: any = await create(data);
      console.log(newPlantsList);
      if (newPlantsList) navigate('/plants');
    }
  };

  return (
    <div className={styles.edit}>
      <div className={styles.content}>
        {plantsListId && isGetLoading && <p>Loading</p>}
        {((plantsListId && plantsList) || !plantsListId) && (
          <PlantsListForm
            ref={formPlantsListRef}
            plantsList={plantsList}
            onSubmit={handleSubmit}
            submitIsLoading={isPostLoading || isPutLoading}
          />
        )}
        {errorApi?.message && (
          <ErrorField message={errorApi.message} />
        )}
      </div>
    </div>
  );
};

export default EditPlantsList;
