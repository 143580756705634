import React, { useState } from 'react';
import { IoAdd, IoChevronDownOutline } from 'react-icons/io5';
import {
  Link, useNavigate, useSearchParams,
} from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import styles from './list.module.scss';

import InputSearch from '../../../components/InputSearch';
import useTagsToOptionsLang, { ITagTranslated } from '../../../hooks/useTagsToOptionsLang';
// import CentersTable from '../../../components/CentersTable';
import ButtonExpandable from '../../../components/ButtonExpandable';
import FilterSearchParam from '../../../components/FilterSearchParam';
import { useCreateArticle, useGetArticles, useGetArticlesList } from '../../../hooks/articles';
import { IArticle } from '../../../types/article';

const ArcticleCard = ({ article }: { article: IArticle }) => {
  const tags = useTagsToOptionsLang(article?.tags || []);
  return <Link
      to={`/actus/edit/${article._id}`}
      className={styles.card}
    >
    <div className={styles.cover}>
      {article?.cover?.path && <img src={`${process.env.REACT_APP_API_URL}/files/public/${article?.cover?.path}`} alt={article.title}/>}
    </div>
    <div className={styles.infos}>
      <div className={styles.tags}>
        {tags.map((t: ITagTranslated) => <p key={`${article?._id}${t.value}`}>{t.label}</p>)}
      </div>
      <p className={styles.creator}>
        {article?.creator?.profile?.firstName} {article?.creator?.profile?.lastName}
      </p>
      <h3>{article?.title}</h3>
      <p>{article?.description}</p>
    </div>
  </Link>;
};

const ArticlePanel = ({ title, articles }: { title: string, articles: IArticle[] }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div className={styles.panel}>
      <div className={styles['panel-header']}>
        <h2>{title}</h2>
        <div className={styles.actions}>
          <p>{articles?.length}</p>
          <button
            type="button"
            className={isOpen ? styles.open : ''}
            onClick={() => setIsOpen(!isOpen)}
          >
            <IoChevronDownOutline />
          </button>
        </div>
      </div>
      {isOpen
        && <div className={styles.grid}>
        {articles?.map((a:IArticle) => <ArcticleCard
          key={a._id}
          article={a}
        />)}
        </div>
      }
    </div>
  );
};

const ArticlesList = () => {
  const { t } = useTranslation('articles');
  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', ...paramsObject } = Object.fromEntries(searchParams);
  const navigate = useNavigate();

  const debouncedSearch = useDebounce<string>(search, 500);
  const { data: list } = useGetArticlesList();

  const months = list?.months.map((d: string) => ({
    value: d,
    label: new Intl.DateTimeFormat('fr-FR', { month: 'long', year: 'numeric' })
      .format(new Date(d)),
  })) || [];

  const {
    mutateAsync: create,
  } = useCreateArticle();

  const { data, isLoading } = useGetArticles({
    ...paramsObject,
    search: debouncedSearch,
  });
  const articles = data || [];

  const tags = useTagsToOptionsLang(list?.tags || []);

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const handleCreate = async () => {
    const { article: newArticle }: any = await create({ title: t('create') });
    if (newArticle) navigate(`/actus/edit/${newArticle?._id}`);
  };

  return (
    <div className={styles.articlesList}>
      <div className={styles.header}>
        <h1>
          {t('title')}
          <span>{articles.length}</span>
        </h1>
        <div>
          <InputSearch
            handleSearch={handleSearch}
            defaultValue={search}
          />
          <div
            onClick={() => handleCreate()}
          >
            <ButtonExpandable
              icon={<IoAdd size={24} />}
              text={t('new')}
            />
          </div>
        </div>
      </div>
      {isLoading && <p>Loading</p>}
      <div className={styles.filters}>
        <FilterSearchParam
          label={t('filters.tag')}
          name={'tag'}
          options={tags || []}
        />
         <FilterSearchParam
          label={t('filters.organisation')}
          name={'organisation'}
          options={list?.organisations || []}
          feminine
        />
         <FilterSearchParam
          label={t('filters.region')}
          name={'region'}
          options={list?.regions || []}
          feminine
        />
        <FilterSearchParam
          label={t('filters.date')}
          name={'publicationDate'}
          options={months || []}
          feminine
        />
      </div>
      <div className={styles.content}>
        <ArticlePanel
          title={t('lists.draft')}
          articles={articles.filter((a:IArticle) => !a.isPublished)}
        />
        <ArticlePanel
          title={t('lists.published')}
          articles={articles.filter((a:IArticle) => a.isPublished)}
        />
      </div>
    </div>
  );
};

export default ArticlesList;
