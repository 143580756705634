import React, {
  useState, useMemo, useRef,
  useEffect,
} from 'react';
import {
  Control, UseFormGetValues, UseFormSetValue, UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetListQuizQuery } from '../../../services/api/quiz';
import {
  InputText,
  ListTags,
  StyledSelect,
  Textarea,
  InputNumber,
} from '../../../lib/HooksFormFields';
import Modal from '../../../lib/Modal';
import SelectPlants from './SelectPlants';
import QuestionsDistribution from './QuestionsDistribution';
import styles from './quiz-form.module.scss';
import RecognitionAdvancedSettings from './RecognitionAdvancedSettings';
import SelectMultiple from '../../SelectMultiple';
import useTagsToOptionsLang from '../../../hooks/useTagsToOptionsLang';

type PlantProps = {
  value: string
  label: string | null
};

type InputsProps = {
  [key: string]: {
    value: number;
    // label: string;
    // range: string;
  }
};

const QuizForm = ({
  control,
  getValues,
  setValue,
  watch,
} : {
  control: Control<any>,
  getValues: UseFormGetValues<any>,
  setValue: UseFormSetValue<any>,
  watch: UseFormWatch<any>,
}) => {
  const { t } = useTranslation(['createQuiz', 'global']);
  const modalPlants = useRef<any>();
  const selectPlantsRef = useRef<any>();
  const { data: list = {} } = useGetListQuizQuery();
  const [plantsRecognition, setPlantsRecognition] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<'knowledge' | 'recognition'>('recognition');
  const [advancedMode, setAdvancedMode] = useState({
    recognition: false,
    knowledge: false,
  });

  const {
    tags = [],
  } = list || {};

  const tagsTranslated = useTagsToOptionsLang(tags);

  const QRcount = parseInt(watch('settings.recognitionCount') || 0, 10);
  const QCcount = parseInt(watch('settings.knowledgeCount') || 0, 10);

  const recognitionSettings = watch('settings.recognition');
  const knowledgeSettings = watch('settings.knowledge');

  const labels = {
    QRU: {
      label: 'QRU',
      range: 'QRU',
      name: 'QRU',
    },
    QRM: {
      label: 'QRM',
      range: 'QRM',
      name: 'QRM',
    },
    QRA: {
      label: 'QRA',
      range: 'A',
      name: 'Appariement (A)',
    },
    QRT: {
      label: 'QRT',
      range: 'RC',
      name: 'Réponse courte (RC)',
    },
  };

  function remainderFunction(inputs: InputsProps, total: number) {
    return total % Object.keys(inputs).length;
  }

  // function to divide the questions equally and create range values
  function distribution(inputs: InputsProps, totalDistributable: number, remainder: number) {
    const obj: any = { ...inputs };
    const totalLessRemainder = totalDistributable - remainder;
    const equalNumber = totalLessRemainder / Object.keys(inputs).length;
    const newEntries = Object.entries(obj);
    newEntries.forEach(([key], i: number) => {
      if (i === newEntries.length - 1) {
        obj[key].value = equalNumber + remainder;
      } else {
        obj[key].value = equalNumber;
      }
    });

    return obj;
  }

  // Handle recognition questions count change
  useEffect(() => {
    if (!recognitionSettings) return;
    let isDistributed = false;
    const minimalRecognitionSettings = {
      QRU: recognitionSettings.QRU,
      QRM: recognitionSettings.QRM,
      QRA: recognitionSettings.QRA,
      QRT: recognitionSettings.QRT,
    };

    const total = Object.values(minimalRecognitionSettings)
      .reduce((acc, curr: any) => acc + curr.value, 0);

    if (total === QRcount) isDistributed = true;

    if (!isDistributed) {
      const remainder = remainderFunction(minimalRecognitionSettings, QRcount);
      const distributedInputs = distribution(minimalRecognitionSettings, QRcount, remainder);
      Object.entries({
        ...distributedInputs,
      }).forEach(([k, value]): any => {
        setValue(`settings.recognition.${k}`, value);
      });
    }
  }, [recognitionSettings, QRcount]);

  // Handle knowledge questions count change
  useEffect(() => {
    if (!knowledgeSettings) return;
    let isDistributed = false;
    const total = Object.values(knowledgeSettings)
      .reduce((acc, curr: any) => acc + curr.value, 0);

    if (total === QCcount) isDistributed = true;

    if (!isDistributed) {
      const remainder = remainderFunction(knowledgeSettings, QCcount);
      const distributedInputs = distribution(knowledgeSettings, QCcount, remainder);
      setValue('settings.knowledge', distributedInputs);
    }
  }, [knowledgeSettings, QCcount]);

  const addRecognitionPlants = () => {
    const plants = selectPlantsRef.current.getPlants();
    modalPlants.current.close();
    setPlantsRecognition(plants);
  };

  const toggleAdvancedMode = (key: 'recognition' | 'knowledge') => {
    setAdvancedMode((state) => ({ ...state, [key]: !state[key] }));
  };

  const optionsTaxonomicalGroup = useMemo(() => {
    if (!list.taxonomical_group) return [];
    const options = list.taxonomical_group.map((d: string) => ({
      value: d,
      label: d,
    }));
    return options;
  }, [list.taxonomical_group]);

  const optionsMorphologicalGroup = useMemo(() => {
    if (!list.morphological_group) return [];
    const options = list.morphological_group.map((d: string) => ({
      value: d,
      label: d,
    }));
    return options;
  }, [list.morphological_group]);

  const optionsConditionsOfUseGroup = useMemo(() => {
    if (!list.conditions_of_use) return [];
    const options = list.conditions_of_use.map((d: string) => ({
      value: d,
      label: d,
    }));
    return options;
  }, [list.conditions_of_use]);

  return (
    <div className={styles.form}>
      <Modal ref={modalPlants}>
        <div className={styles.modalPlants}>
          <div className={styles.header}>
            <h5>{t('fields.addPlants', { ns: 'createQuiz' })}</h5>
            <p onClick={() => modalPlants.current.close()}>Fermer X</p>
          </div>
          <SelectPlants ref={selectPlantsRef} defaultPlants={plantsRecognition} />
          <div className={styles.containerBtn}>
            <button onClick={() => addRecognitionPlants()}>{t('validate', { ns: 'global' })}</button>
          </div>
        </div>
      </Modal>
      {/* General */}
      <section>
        <div className={styles.containerField}>
          <InputText
            name="name"
            control={control}
            heading={1}
            placeholder={t('fields.placeholderName', { ns: 'createQuiz' })}
            rules={{
              required: t('fields.requiredName', { ns: 'createQuiz' }),
            }}
          />
        </div>
        <div className={styles.containerField}>
          <Textarea
            name="description"
            control={control}
            placeholder={t('fields.placeholderDesc', { ns: 'createQuiz' })}
            rules={{
              required: t('fields.requiredDesc', { ns: 'createQuiz' }),
            }}
          />
        </div>
        <div className={styles.tags}>
          <h3>{t('fields.tags')}</h3>
          <SelectMultiple
            name="tags"
            control={control}
            options={tagsTranslated}
          />
        </div>
      </section>
      <hr />
      {/* Questions count */}
      <section>
        <div className={styles.questionsCount}>
          <h4>{t('questionsDistribution')}</h4>
          <div className={styles.fields}>
            <div className={styles.containerField}>
              <InputNumber
                name="settings.recognitionCount"
                control={control}
                label={t('fields.questionsCountReco', { ns: 'createQuiz' })}
                defaultValue={0}
                min='0'
                rules={{
                  required: t('fields.requiredRecognitionCount', { ns: 'createQuiz' }),
                  validate: (value: number) => {
                    const knowledgeCount = getValues('settings.knowledgeCount') || 0;
                    return (knowledgeCount + value) === 0
                      ? t('fields.notZeroCount', { ns: 'createQuiz' })
                      : true;
                  },
                }}
              />
            </div>
            <div className={styles.containerField}>
              <InputNumber
                name="settings.knowledgeCount"
                control={control}
                label={t('fields.questionsCountKnow', { ns: 'createQuiz' })}
                defaultValue={0}
                min='0'
                rules={{
                  required: t('fields.requiredKnowledgeCount', { ns: 'createQuiz' }),
                  validate: (value: number) => {
                    const recognitionCount = getValues('settings.recognitionCount') || 0;
                    return (recognitionCount + value) === 0
                      ? t('fields.notZeroCount', { ns: 'createQuiz' })
                      : true;
                  },
                }}
              />
            </div>
          </div>
          <div>
            <div className={styles['count-container']}>
              {/* <h4>{t('questions')}</h4> */}
              <div>
                <p>{t('fields.questionsCount', { ns: 'createQuiz' })}</p>
                <span className={styles.count}>{QRcount + QCcount}</span>
              </div>
            </div>
          </div>
          <div>
            <h4>
              {t('generalSettings', { ns: 'createQuiz' })}
            </h4>
            <div className={`${styles.typesPlantName} ${styles.containerField}`}>
              <ListTags
                name='typePlantName'
                label={t('fields.plantsName', { ns: 'createQuiz' })}
                control={control}
                tags={[
                  { label: t('fields.commonNames', { ns: 'createQuiz' }), value: 'common_names' },
                  { label: t('fields.scientificNames', { ns: 'createQuiz' }), value: 'scientific_name' },
                  { label: t('fields.randomName', { ns: 'createQuiz' }), value: 'random' },
                ]}
              />
            </div>
          </div>
        </div>
      </section>
      <div className={styles.tabs}>
        <ul>
          <li
            className={activeTab === 'recognition' ? styles.actived : ''}
            onClick={() => setActiveTab('recognition')}
          >
            {t('fields.recognition')}
            </li>
          <li
            className={activeTab === 'knowledge' ? styles.actived : ''}
            onClick={() => setActiveTab('knowledge')}
          >
            {t('fields.knowledge')}
          </li>
        </ul>
      </div>
      {/* Recognition */}
      <section style={activeTab === 'recognition' ? { display: 'block' } : { display: 'none' }}>
          <div className={styles.recognition}>
            <div>
              <h5>{t('fields.questionsTypes', { ns: 'createQuiz' })}</h5>
              {recognitionSettings && (
                <div className={styles.distribution}>
                  <QuestionsDistribution
                    questionsType="Question de reconnaissance"
                    idInput="recognition_questions"
                    totalQuestions={QRcount}
                    labels={labels}
                    inputs={{
                      QRU: recognitionSettings.QRU,
                      QRM: recognitionSettings.QRM,
                      QRA: recognitionSettings.QRA,
                      QRT: recognitionSettings.QRT,
                    }}
                    setInputs={(obj: any) => {
                      setValue('settings.recognition.QRU', obj.QRU);
                      setValue('settings.recognition.QRM', obj.QRM);
                      setValue('settings.recognition.QRA', obj.QRA);
                      setValue('settings.recognition.QRT', obj.QRT);
                    }}
                  />
                </div>
              )}
              {advancedMode.recognition && <div style={{ marginBottom: 20 }}><hr /></div>}
              <button
                className={`${styles.advancedModeButton} secondary`}
                onClick={() => toggleAdvancedMode('recognition')}
              >
                {t('advancedMode', { ns: 'createQuiz' })}
              </button>
              {advancedMode.recognition && (
                <RecognitionAdvancedSettings />
              )}
            </div>
            <h5>{t('fields.groups', { ns: 'createQuiz' })}</h5>
            <div className={styles.selectFiltersPlant}>
              <div className={styles.containerField}>
                <StyledSelect
                  name="settings.recognitionFilters.morphological_group"
                  control={control}
                  label={t('fields.grpMorph', { ns: 'createQuiz' })}
                  options={optionsMorphologicalGroup}
                  isClearable
                />
              </div>
              <div className={styles.containerField}>
                <StyledSelect
                  name="settings.recognitionFilters.taxonomical_group"
                  control={control}
                  label={t('fields.grpTaxo', { ns: 'createQuiz' })}
                  options={optionsTaxonomicalGroup}
                  isClearable
                />
              </div>
              <div className={styles.containerField}>
                <StyledSelect
                  name="settings.recognitionFilters.conditions_of_use"
                  control={control}
                  label={t('fields.usages', { ns: 'createQuiz' })}
                  options={optionsConditionsOfUseGroup}
                  isClearable
                />
              </div>
            </div>
            <h5>{t('fields.listPlants.label', { ns: 'createQuiz' })}</h5>
            <div>
              <div className={`${styles.containerField} ${styles.selectionPlants}`}>
                <ListTags
                  name='settings.recognitionSelection'
                  label={t('fields.listPlants.label', { ns: 'createQuiz' })}
                  control={control}
                  inline
                  tags={[
                    { label: t('fields.listPlants.optionAll', { ns: 'createQuiz' }), value: 'all' },
                    { label: t('fields.listPlants.optionTepik', { ns: 'createQuiz' }), value: 'tepik' },
                  ]}
                />
              </div>
            </div>
            <div className={styles.specificPlants}>
              <button onClick={() => modalPlants.current.open()}>{t('fields.addPlants', { ns: 'createQuiz' })}</button>
              <div>
                <ul>
                  {plantsRecognition.map((plant: PlantProps) => (
                    <li key={`plant-${plant.value}`}>
                      <div className={styles.plant}>
                        <p>{plant.label}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
      </section>
      {/* Knowledge */}
      <section style={activeTab === 'knowledge' ? { display: 'block' } : { display: 'none' }}>        <div className={styles.knowledge}>
        <div>
        <h5>{t('fields.questionsTypes', { ns: 'createQuiz' })}</h5>
        {knowledgeSettings && (
          <div className={styles.distribution}>
            <QuestionsDistribution
              questionsType="Question de connaissance"
              idInput="knowledge_questions"
              totalQuestions={QCcount}
              labels={labels}
              inputs={knowledgeSettings}
              setInputs={(obj: any) => {
                setValue('settings.knowledge', obj);
              }}
            />
          </div>
        )}
        </div>
        <h5>{t('fields.groups', { ns: 'createQuiz' })}</h5>
        <div className={styles.selectFiltersPlant}>
          <div className={styles.containerField}>
            <StyledSelect
              name="settings.knowledgeFilters.morphological_group"
              control={control}
              label={t('fields.grpMorph', { ns: 'createQuiz' })}
              options={optionsMorphologicalGroup}
              isClearable
            />
          </div>
          <div className={styles.containerField}>
            <StyledSelect
              name="settings.knowledgeFilters.taxonomical_group"
              control={control}
              label={t('fields.grpTaxo', { ns: 'createQuiz' })}
              options={optionsTaxonomicalGroup}
              isClearable
            />
          </div>
          <div className={styles.containerField}>
            <StyledSelect
              name="settings.knowledgeFilters.conditions_of_use"
              control={control}
              label={t('fields.usages', { ns: 'createQuiz' })}
              options={optionsConditionsOfUseGroup}
              isClearable
            />
          </div>
        </div>
        <div>
          <h5>{t('fields.listPlants.label', { ns: 'createQuiz' })}</h5>
          <div className={`${styles.containerField} ${styles.selectionPlants}`}>
            <ListTags
              name='settings.knowledgeSelection'
              label={t('fields.listPlants.label', { ns: 'createQuiz' })}
              control={control}
              inline
              tags={[
                { label: t('fields.listPlants.optionAll', { ns: 'createQuiz' }), value: 'all' },
                { label: t('fields.listPlants.optionTepik', { ns: 'createQuiz' }), value: 'tepik' },
              ]}
            />
          </div>
        </div>
        </div>
      </section>
    </div>
  );
};

export default QuizForm;
