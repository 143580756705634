import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import globalEn from './en/global.json';
import accountEn from './en/account.json';
import tepikTeamEn from './en/tepik-team.json';
import listProfilsEn from './en/list-profils.json';
import tagsEn from './en/tags.json';
import createProfilEn from './en/create-profil.json';
import organisationsEn from './en/organisations.json';
import createOrganisationEn from './en/create-organisation.json';
import organisationUsersEn from './en/organisation-users.json';
import createUserEn from './en/create-user.json';
import createQuizEn from './en/create-quiz.json';
import quizEn from './en/quiz.json';
import centersEn from './en/centers.json';
import createCenterEn from './en/create-center.json';
import headerEn from './en/header.json';
import formationsEn from './en/formations.json';
import plantsListsEn from './en/plantsList.json';
import authEn from './en/auth.json';
import articlesEn from './en/articles.json';
import sessionsEn from './en/sessions.json';

import globalFr from './fr/global.json';
import accountFr from './fr/account.json';
import tepikTeamFr from './fr/tepik-team.json';
import listProfilsFr from './fr/list-profils.json';
import tagsFr from './fr/tags.json';
import createProfilFr from './fr/create-profil.json';
import organisationsFr from './fr/organisations.json';
import createOrganisationFr from './fr/create-organisation.json';
import organisationUsersFr from './fr/organisation-users.json';
import createUserFr from './fr/create-user.json';
import createQuizFr from './fr/create-quiz.json';
import quizFr from './fr/quiz.json';
import centersFr from './fr/centers.json';
import createCenterFr from './fr/create-center.json';
import headerFr from './fr/header.json';
import formationsFr from './fr/formations.json';
import plantsListsFr from './fr/plantsList.json';
import authFr from './fr/auth.json';
import articlesFr from './fr/articles.json';
import sessionsFr from './fr/sessions.json';

i18next.use(initReactI18next).init({
  lng: 'fr', // if you're using a language detector, do not define the lng option
  debug: process.env.NODE_ENV !== 'production',
  resources: {
    en: {
      auth: authEn,
      global: globalEn,
      account: accountEn,
      tepikTeam: tepikTeamEn,
      listProfils: listProfilsEn,
      tags: tagsEn,
      formations: formationsEn,
      createProfil: createProfilEn,
      organisations: organisationsEn,
      createOrganisation: createOrganisationEn,
      createUser: createUserEn,
      organisationUsers: organisationUsersEn,
      createQuiz: createQuizEn,
      quiz: quizEn,
      centers: centersEn,
      createCenter: createCenterEn,
      header: headerEn,
      plantsLists: plantsListsEn,
      articles: articlesEn,
      sessions: sessionsEn,
    },
    fr: {
      auth: authFr,
      global: globalFr,
      account: accountFr,
      tepikTeam: tepikTeamFr,
      listProfils: listProfilsFr,
      formations: formationsFr,
      tags: tagsFr,
      createProfil: createProfilFr,
      organisations: organisationsFr,
      createOrganisation: createOrganisationFr,
      createUser: createUserFr,
      organisationUsers: organisationUsersFr,
      createQuiz: createQuizFr,
      quiz: quizFr,
      centers: centersFr,
      createCenter: createCenterFr,
      header: headerFr,
      plantsLists: plantsListsFr,
      articles: articlesFr,
      sessions: sessionsFr,
    },
  },
});
