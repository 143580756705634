import React, {
  forwardRef, useEffect, useImperativeHandle,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  InputText,
  InputEmail,
  InputFormatPattern,
  StyledSelect,
  ErrorField,
  Textarea,
} from '../../../lib/HooksFormFields';
// import Tag from '../../Tag';
import { useGetOrganisationsList } from '../../../hooks/organisations';
import styles from './organisation-form.module.scss';
import { IOrganisation } from '../../../types/organisations';
import useOptionsLang from '../../../hooks/useOptionsLang';
import SelectMultiple from '../../SelectMultiple';
import useTagsToOptionsLang from '../../../hooks/useTagsToOptionsLang';
import { IOption } from '../../../types/options';

const OrganisationForm = forwardRef(({ organisation } : { organisation?: IOrganisation }, ref) => {
  const { t } = useTranslation(['global', 'createOrganisation']);

  const { data: list } = useGetOrganisationsList();
  const {
    control,
    trigger,
    getValues,
    reset,
    formState: { errors },
  } = useForm(organisation ? {
    defaultValues: organisation,
  } : {});

  const {
    tags = [],
    organisations = [],
    organisationSize = [],
    organisationMembershipValor = [],
    organisationTypes = [],
  } = list || {};

  const tagsTranslated = useTagsToOptionsLang(tags);
  const organisationSizeTranslated = useOptionsLang(organisationSize);
  const organisationTypesTranslated = useOptionsLang(organisationTypes);
  const organisationMembershipValorTranslated = useOptionsLang(organisationMembershipValor);

  const errorsForm : any = errors;

  useEffect(() => {
    if (organisation) {
      reset({
        ...organisation,
        organisation: organisation?.organisation?._id,
        tags: organisation?.tags?.map((tag: any) => tag._id) || [],
      });
    }
  }, [organisation]);

  useImperativeHandle(ref, () => ({
    submit: async () => {
      const isValid = await trigger();
      let data = null;
      if (isValid) {
        data = getValues();
        data = {
          type: data.type,
          name: data.name,
          email: data.email,
          phone: data.phone,
          siret: data.siret,
          codeAPE: data.codeAPE,
          tva: data.tva,
          size: data.size,
          membershipValor: data.membershipValor,
          tags: data.tags,
          note: data.note,
          organisation: data.organisation || null,
          address: {
            street: data?.address?.street || '',
            zipCode: data?.address?.zipCode || '',
            region: data?.address?.region || '',
          },
        };
      }
      return data;
    },
  }), []);

  return (
    <div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <StyledSelect
            name="type"
            control={control}
            label={t('fields.type', { ns: 'createOrganisation' })}
            options={organisationTypesTranslated}
            rules={{
              required: t('requiredField', { ns: 'global' }),
            }}
          />
          {errorsForm?.type && <ErrorField message={errorsForm.type.message} />}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputText
            name="name"
            control={control}
            label={t('fields.name', { ns: 'createOrganisation' })}
            rules={{
              required: t('requiredField', { ns: 'global' }),
            }}
          />
          {errorsForm?.name && <ErrorField message={errorsForm.name.message} />}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputText
            name="address.street"
            control={control}
            label={t('fields.address', { ns: 'createOrganisation' })}
          />
          {errorsForm?.address?.street && (
            <ErrorField message={errorsForm.address?.street?.message} />
          )}
        </div>
        <div className={styles.containerField}>
          <InputText
            name="address.zipCode"
            control={control}
            label={t('fields.zipCode', { ns: 'createOrganisation' })}
          />
          {errorsForm?.address?.zipCode && (
            <ErrorField message={errorsForm.address?.zipCode?.message} />
          )}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputText
            name="address.region"
            control={control}
            label={t('fields.region', { ns: 'createOrganisation' })}
          />
          {errorsForm?.address?.region && (
            <ErrorField message={errorsForm.address?.region?.message} />
          )}
        </div>
      </div>
      <div className={styles.row2}>
        <div className={styles.containerField}>
          <StyledSelect
            name="size"
            control={control}
            label={t('fields.size', { ns: 'createOrganisation' })}
            options={organisationSizeTranslated}
            isClearable
          />
        </div>
        <div className={styles.containerField}>
          <StyledSelect
            name="membershipValor"
            control={control}
            label={t('fields.membershipValor', { ns: 'createOrganisation' })}
            options={organisationMembershipValorTranslated}
            isClearable
          />
        </div>
      </div>
      <div className={styles.row2}>
        <div className={styles.containerField}>
          <StyledSelect
            name="organisation"
            control={control}
            label={t('fields.organisation', { ns: 'createOrganisation' })}
            options={organisations.filter(
              (o: IOption) => o.value !== organisation?._id,
            )}
            isClearable
          />
        </div>
      </div>
      <div className={styles.row2}>
        <div className={styles.containerField}>
          <InputFormatPattern
            name="phone"
            control={control}
            label={t('fields.phone', { ns: 'createOrganisation' })}
            format="## ## ## ## ##"
            rules={{
              pattern: {
                value: /([0-9]{2,2}\s){4,5}([0-9]{2,2})/,
                message: 'Le numéro de téléphone n\'est pas valide',
              },
            }}
          />
          {errorsForm?.phone && (
            <ErrorField message={errorsForm?.phone.message} />
          )}
        </div>
        <div className={styles.containerField}>
          <InputEmail
            name="email"
            control={control}
            label={t('fields.email', { ns: 'createOrganisation' })}
          />
          {errors.email && <ErrorField message={errors.email.message} />}
        </div>
      </div>
      <div className={styles.companyFields}>
        <div className={styles.containerField}>
          <InputText
            name="siret"
            control={control}
            label={t('fields.siret', { ns: 'createOrganisation' })}
            rules={{
              pattern: {
                value: /^.{9,14}$/,
                message: 'Le SIRET n\'est pas valide',
              },
            }}
          />
          {errors.siret && <ErrorField message={errors.siret.message} />}
        </div>
        <div className={styles.containerField}>
          <InputText
            name="codeAPE"
            control={control}
            label={t('fields.codeAPE', { ns: 'createOrganisation' })}
          />
        </div>
        <div className={styles.containerField}>
          <InputText
            name="tva"
            control={control}
            label={t('fields.tva', { ns: 'createOrganisation' })}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.profiles}>
          <SelectMultiple
            label={t('title', { ns: 'tags' })}
            name="tags"
            control={control}
            options={tagsTranslated}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <Textarea
            name="note"
            control={control}
            label={t('fields.note', { ns: 'createOrganisation' })}
          />
        </div>
      </div>
    </div>
  );
});

export default OrganisationForm;
