import React, { useMemo, useEffect, useCallback } from 'react';
import {
  NavLink, useParams, useNavigate, useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { FieldValues, useForm } from 'react-hook-form';
import QuizForm from '../../../../components/form/QuizForm';
import { get, equals } from '../../../../utils';
import { ErrorField } from '../../../../lib/HooksFormFields';
import withModalConfirm from '../../../../lib/withModalConfirm';
import styles from './edit-mode.module.scss';
import {
  useGetQuizQuery,
  useUpdateQuizMutation,
  useDeleteQuizMutation,
  useGetListQuizQuery,
} from '../../../../services/api/quiz';
import Loader from '../../../../components/Loader';
import AdditionnalQuizSettings from '../../../../components/form/QuizForm/AdditionnalQuizSettings';
import ShareQuizSettings from '../../../../components/form/QuizForm/ShareSettings';
import useOptionsLang from '../../../../hooks/useOptionsLang';
import QuizPicture from '../../../../components/form/QuizForm/QuizPicture';
import { ITag } from '../../../../hooks/useTagsToOptionsLang';

const requiredFields = [
  'name',
  'description',
  'settings.recognitionCount',
  'settings.recognitionCount',
  'settings.recognition.QRU',
  'settings.recognition.QRM',
  'settings.recognition.QRA',
  'settings.knowledge.QRU',
  'settings.knowledge.QRM',
  'settings.knowledge.QRA',
];

const EditQuiz = ({
  confirm,
} : {
  confirm: any
}) => {
  const { t } = useTranslation(['global', 'createQuiz']);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { id } = params;
  const { data: quiz, isLoading: isGetLoading, error: errorGet } = useGetQuizQuery(id || skipToken);
  const [updateQuiz, { isLoading: isUpdateLoading, error: errorUpdate }] = useUpdateQuizMutation();
  const [deleteQuiz, { isLoading: isDeleteLoading, error: errorDelete }] = useDeleteQuizMutation();
  const errorRequest: any = errorGet || errorUpdate || errorDelete;
  const { data: list = {} } = useGetListQuizQuery();
  const statusOptions = useOptionsLang(list?.status || []);
  const status : string | undefined = statusOptions.find((d) => d.value === quiz?.status)?.label;

  const {
    control,
    watch,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!quiz) return;

    const { settings } = quiz;
    const parsedRecognition: any = {};
    const parsedKnowledge: any = {};

    Object.entries(settings.recognition).forEach(([key, value]: any) => {
      parsedRecognition[key] = { value };
    });

    Object.entries(settings.knowledge).forEach(([key, value]: any) => {
      parsedKnowledge[key] = { value };
    });

    reset({
      name: quiz.name,
      description: quiz.description,
      status: quiz.status,
      typePlantName: quiz.typePlantName,
      type: quiz.type,
      lang: quiz.lang,
      tags: quiz?.tags.map((tag:ITag) => tag._id),
      settings: {
        ...settings,
        recognition: parsedRecognition,
        knowledge: parsedKnowledge,
      },
      limitedTime: quiz.limitedTime,
      monitoredInterface: quiz.monitoredInterface,
      duration: quiz.duration,
      allowPrevious: quiz.allowPrevious,
      showCorrections: quiz.showCorrections,
    });
  }, [quiz]);

  const submitUpdateQuiz = async (data: FieldValues) => {
    if (!quiz) return;

    const { settings } = data;
    const parsedRecognition: any = {};
    const parsedKnowledge: any = {};

    Object.entries(settings.recognition).forEach(([key, d]: any) => {
      parsedRecognition[key] = d.value;
    });

    Object.entries(settings.knowledge).forEach(([key, d]: any) => {
      parsedKnowledge[key] = d.value;
    });

    await updateQuiz({
      _id: quiz._id,
      ...data,
      settings: {
        ...settings,
        recognition: parsedRecognition,
        knowledge: parsedKnowledge,
      },
    });
  };

  const submit = async () => {
    if (!quiz) return;
    const settings = getValues('settings') || {};
    const typePlantName = getValues('typePlantName');

    const parsedRecognition: any = {};
    const parsedKnowledge: any = {};

    Object.entries(settings?.recognition).forEach(([key, d]: any) => {
      parsedRecognition[key] = d.value;
    });

    Object.entries(settings?.knowledge).forEach(([key, d]: any) => {
      parsedKnowledge[key] = d.value;
    });

    const questionsSettingsModified = (settings && !equals({
      ...quiz.settings,
      knowledgeFilters: {
        morphological_group: quiz.settings?.knowledgeFilters?.morphological_group,
        taxonomical_group: quiz.settings?.knowledgeFilters?.taxonomical_group,
        conditions_of_use: quiz.settings?.knowledgeFilters?.conditions_of_use,
      },
      recognitionFilters: {
        morphological_group: quiz.settings?.recognitionFilters?.morphological_group,
        taxonomical_group: quiz.settings?.recognitionFilters?.taxonomical_group,
        conditions_of_use: quiz.settings?.recognitionFilters?.conditions_of_use,
      },
      typePlantName: quiz.typePlantName,
    }, {
      ...settings,
      recognition: parsedRecognition,
      knowledge: parsedKnowledge,
      typePlantName,
    }));

    if (questionsSettingsModified) {
      confirm(
        t('msgConfirmModify', { ns: 'createQuiz' }),
        () => handleSubmit(submitUpdateQuiz)(),
      );
    } else handleSubmit(submitUpdateQuiz)();
  };

  const handleDeleteQuiz = useCallback(async () => {
    if (!quiz) return;
    confirm(
      t('msgConfirmDelete', { ns: 'createQuiz' }),
      async () => {
        const res : any = await deleteQuiz(quiz._id);
        if (!res.error) navigate('/quiz');
      },
    );
  }, [quiz]);

  const errorMessageTypes = useMemo(() => {
    if (!quiz || !quiz.errorTypes || (quiz.errorTypes && quiz.errorTypes.length === 0)) return '';
    let errorMessage = t('msgConfirmModify', { ns: 'createQuiz', countQuestions: quiz.questions.length });
    errorMessage += ` ${quiz.errorTypes.join(', ')}`;
    return errorMessage;
  }, [quiz]);

  if (isGetLoading || !quiz) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.edit}>
      <div className={styles.form}>
        <QuizForm
          control={control}
          watch={watch}
          getValues={getValues}
          setValue={setValue}
        />
      </div>
      <div className={styles.moreSettings}>
        <div>
          <div className={styles.switchMode}>
            <NavLink
              to={`/quiz/edit/${id}`}
              className={() => (location.pathname === `/quiz/edit/${id}`
                ? styles.actived
                : '')
            }
            >
              Mode édition
            </NavLink>
            <NavLink
              to={`/quiz/edit/${id}/preview`}
              className={() => (location.pathname === `/quiz/edit/${id}/preview` ? styles.actived : '')}
            >
              Mode aperçu
            </NavLink>
          </div>
          <button
            type='button'
            onClick={submit}
            disabled={isUpdateLoading || isDeleteLoading}
          >
            <span>{t('save', { ns: 'createQuiz' })}</span>
            {isUpdateLoading && (
              <span className='icon loader'>
                <div className={styles.dotLoader} />
              </span>
            )}
          </button>
          <div className={styles.lastUpdate}>
              <div className={styles.status}>
                <p>{t('settings.fields.status', { ns: 'createQuiz' })} : <span>{status}</span></p>
              </div>
              <p>
                {t('lastSave', { ns: 'createQuiz' })}
                {` ${format(quiz.updatedAt, 'dd / MM / yy', { locale: fr })}`}
                {` à ${format(quiz.updatedAt, 'HH', { locale: fr })}`}
                h
                {`${format(quiz.updatedAt, 'mm', { locale: fr })}`}
              </p>
          </div>
          {!!Object.keys(errors).length
          && requiredFields.map((key) => (get(errors, key)?.message ? (
            <div key={key} className={styles.errorMessage}>
              <ErrorField
                key={`error-${key}`}
                message={get(errors, key)?.message || ''}
              />
            </div>
          ) : null))
            .filter((d) => d)[0]}
          {errorRequest?.message && (
            <ErrorField message={errorRequest.message} />
          )}
          {errorMessageTypes && (
            <ErrorField message={errorMessageTypes} />
          )}
        </div>
        <AdditionnalQuizSettings
          control={control}
          watchForm={watch}
        />
        <ShareQuizSettings />
        <QuizPicture quiz={quiz} />
        <button
          onClick={() => handleDeleteQuiz()}
          className={`${styles.delete} invisible`}
          disabled={isUpdateLoading || isDeleteLoading}
        >
          {t('delete', { ns: 'createQuiz' })}
        </button>
      </div>
    </div>
  );
};

export default withModalConfirm(EditQuiz);
